// src/components/academy/Certifications.js
import React from 'react';
import './Certifications.css';

const certificationsData = [
  { id: 1, title: 'Certified Full Stack Web Developer', courseLink: '/academy/course-details/1' },
  { id: 2, title: 'Advanced JavaScript Developer Certification', courseLink: '/academy/course-details/2' },
  { id: 3, title: 'Certified Microservices Architect', courseLink: '/academy/course-details/3' },
  { id: 4, title: 'Data Science and Machine Learning Certification', courseLink: '/academy/course-details/4' },
  { id: 5, title: 'Certified Mobile App Developer', courseLink: '/academy/course-details/5' },
  { id: 6, title: 'DevOps Engineering Certification', courseLink: '/academy/course-details/6' },
  { id: 7, title: 'Cybersecurity Fundamentals Certification', courseLink: '/academy/course-details/7' },
  { id: 8, title: 'AI and Deep Learning Certification', courseLink: '/academy/course-details/8' },
  { id: 9, title: 'Blockchain Development Certification', courseLink: '/academy/course-details/9' },
  { id: 10, title: 'Cloud Computing Certification', courseLink: '/academy/course-details/10' },
  { id: 11, title: 'Backend Development Certification', courseLink: '/academy/course-details/11' },
  { id: 12, title: 'Frontend Development Certification', courseLink: '/academy/course-details/12' },
  { id: 13, title: 'CI/CD Implementation Certification', courseLink: '/academy/course-details/13' },
  { id: 14, title: 'Big Data Analytics Certification', courseLink: '/academy/course-details/14' },
  { id: 15, title: 'UI/UX Design Certification', courseLink: '/academy/course-details/15' },
  { id: 16, title: 'Agile Project Management Certification', courseLink: '/academy/course-details/16' },
  { id: 17, title: 'Artificial Intelligence Certification', courseLink: '/academy/course-details/17' },
  { id: 18, title: 'Internet of Things (IoT) Certification', courseLink: '/academy/course-details/18' },
  { id: 19, title: 'Network Engineering Certification', courseLink: '/academy/course-details/19' },
  { id: 20, title: 'Python Programming Certification', courseLink: '/academy/course-details/20' },
  { id: 21, title: 'Web Design Certification', courseLink: '/academy/course-details/21' },
];

function Certifications({ isAdmin }) {
  return (
    <section className="certifications">
      <div className="container">
        <h2 className="headline">Certifications</h2>
        <div className="certification-list">
          {certificationsData.map((certification) => (
            <div className="certification-item" key={certification.id}>
              <h3 className="certification-title">{certification.title}</h3>
              <a href={certification.courseLink} className="register-link">Register for this course</a>
              {isAdmin && (
                <div className="certification-actions">
                  <button className="edit-button">Edit</button>
                  <button className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Certifications;
