import React from 'react';
import './OfficeLocations.css';

const offices = [
  {
    id: 1,
    city: 'Headquarters (HQ)',
    address: 'Watermark Business Park, Karen, Nairobi, Kenya',
    phone: '+254 722 825 304',
    email: 'info@harmosoft.co.ke',
    mapLink: 'https://maps.app.goo.gl/CWmma1s9AJ9H4WPo8',
  },
  {
    id: 2,
    city: 'Development Center',
    address: 'Juja, Kenya',
    phone: '+254 722 825 332',
    email: 'devcenter@harmosoft.co.ke',
    mapLink: 'https://maps.app.goo.gl/YELjVTLU3YuK4HCN6',
  },
  // {
  //   id: 3,
  //   city: 'Sales and Marketing Office',
  //   address: 'Watermark Business Park, Karen, Nairobi, Kenya',
  //   phone: '+254 722 825 306',
  //   email: 'sales@harmosoft.co.ke',
  //   mapLink: 'https://www.google.com/maps/place/Watermark+Business+Park/',
  // },
  {
    id: 4,
    city: 'Customer Support Center',
    address: 'Watermark Business Park, Karen, Nairobi, Kenya',
    phone: '+254 722 825 332',
    email: 'support@harmosoft.co.ke',
    mapLink: 'https://maps.app.goo.gl/CWmma1s9AJ9H4WPo8',
  },
];

function OfficeLocations() {
  return (
    <section className="office-locations">
      <div className="container">
        <h2 className="locations-headline">Our Offices</h2>
        <div className="locations-list">
          {offices.map((office) => (
            <div className="location-item" key={office.id}>
              <h3 className="location-city">{office.city}</h3>
              <p className="location-address">{office.address}</p>
              <p className="location-contact"><strong>Phone:</strong> <a href={`tel:${office.phone}`}>{office.phone}</a></p>
              <p className="location-contact"><strong>Email:</strong> <a href={`mailto:${office.email}`}>{office.email}</a></p>
              <a href={office.mapLink} className="location-map-link" target="_blank" rel="noopener noreferrer">
                View on Map
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default OfficeLocations;
