// src/components/lab/HarmosoftLab.js
import React from 'react';
import './HarmosoftLab.css';

function HarmosoftLab() {
  return (
    <section className="harmosoft-lab">
      <div className="container">
        <h2>Harmosoft Lab</h2>
        <p>
          Harmosoft Lab is a dedicated space where users can physically visit to utilize our resources, including high-performance computers, specialized software, and participate in tech research. The lab offers a variety of services and facilities to cater to different needs, fostering innovation and collaboration.
        </p>
        <h3>Services and Facilities:</h3>
        <div className="services-facilities">
          <div className="plate">
            <h4>High-Performance Computing Access</h4>
            <ul>
              <li>Workstations: Equipped with the latest hardware and software for intensive computing tasks.</li>
              <li>Cloud Access: Secure cloud computing services for scalable processing power.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Specialized Software</h4>
            <ul>
              <li>Development Tools: Access to licensed software for development, design, and data analysis.</li>
              <li>AI and Machine Learning Platforms: Tools and frameworks for building and testing AI models.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Tech Research and Development</h4>
            <ul>
              <li>Research Projects: Participate in ongoing tech research projects led by Harmosoft.</li>
              <li>Collaboration Opportunities: Work with industry experts, researchers, and other users on cutting-edge technology.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Workshops and Training</h4>
            <ul>
              <li>Technical Workshops: Hands-on training sessions on the latest technologies and tools.</li>
              <li>Certification Programs: Courses and certifications in various tech domains.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Innovation Hub</h4>
            <ul>
              <li>Startup Support: Resources and mentoring for startups and entrepreneurs.</li>
              <li>Hackathons and Competitions: Regular events to foster innovation and problem-solving.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Networking and Collaboration Spaces</h4>
            <ul>
              <li>Meeting Rooms: Equipped with presentation tools for collaborative sessions.</li>
              <li>Co-Working Spaces: Open areas for brainstorming and teamwork.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Prototyping and Testing</h4>
            <ul>
              <li>3D Printing and Prototyping: Facilities for creating physical prototypes of tech products.</li>
              <li>Testing Labs: Spaces for rigorous testing of hardware and software solutions.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Library and Resource Center</h4>
            <ul>
              <li>Tech Library: Access to a wide range of technical books, journals, and online resources.</li>
              <li>Research Database: Comprehensive database of tech research papers and publications.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Community Engagement</h4>
            <ul>
              <li>Guest Lectures and Seminars: Regular talks by industry leaders and experts.</li>
              <li>User Groups and Meetups: Platforms for users to connect and share knowledge.</li>
            </ul>
          </div>
          <div className="plate">
            <h4>Support and Mentoring</h4>
            <ul>
              <li>On-Site Support: Assistance from Harmosoft staff for troubleshooting and guidance.</li>
              <li>Mentorship Programs: One-on-one mentoring from experienced professionals.</li>
            </ul>
          </div>
        </div>
        <h3>Book a Service or Facility</h3>
        <form className="booking-form">
          <label>
            Select Service or Facility:
            <select>
              <option value="computing">High-Performance Computing Access</option>
              <option value="software">Specialized Software</option>
              <option value="research">Tech Research and Development</option>
              <option value="workshops">Workshops and Training</option>
              <option value="innovation">Innovation Hub</option>
              <option value="networking">Networking and Collaboration Spaces</option>
              <option value="prototyping">Prototyping and Testing</option>
              <option value="library">Library and Resource Center</option>
              <option value="community">Community Engagement</option>
              <option value="support">Support and Mentoring</option>
            </select>
          </label>
          <label>
            Full Name:
            <input type="text" name="name" required />
          </label>
          <label>
            Email Address:
            <input type="email" name="email" required />
          </label>
          <label>
            Phone Number:
            <input type="tel" name="phone" required />
          </label>
          <label>
            Preferred Date and Time:
            <input type="datetime-local" name="datetime" required />
          </label>
          <label>
            Additional Information:
            <textarea name="additional-info" rows="4"></textarea>
          </label>
          <button type="submit">Submit Booking</button>
        </form>
      </div>
    </section>
  );
}

export default HarmosoftLab;
