// src/components/notFound/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <div className="container">
        <h1 className="not-found-headline">We apologize for the inconvenience.</h1>
        <p className="not-found-message">This page is not yet updated and will be available soon. We are currently working on our website to improve your experience and provide up-to-date information. Please check back later for the updated page.
        </p>
        <Link to="/" className="home-link">Go to Home</Link>
      </div>
    </div>
  );
}

export default NotFound;
