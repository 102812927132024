import React, { useState } from 'react';
import './DemoRequest.css';

const products = [
  'PAWLSAR',
  'HDS - Harmosoft Document Security',
  'AI-Powered Chatbots',
];

function DemoRequest() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    product: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    // Reset form fields
    setFormData({
      name: '',
      email: '',
      product: '',
      message: '',
    });
  };

  return (
    <section className="demo-request">
      <div className="container">
        {!submitted ? (
          <>
            <h2 className="demo-headline">Request a Demo or Trial</h2>
            <form className="demo-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="product">Product</label>
                <select
                  id="product"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a Product</option>
                  {products.map((product, index) => (
                    <option key={index} value={product}>{product}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">Submit Request</button>
            </form>
          </>
        ) : (
          <div className="submit-message">
            <p>Thank you for your request! We will get back to you soon.</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default DemoRequest;
