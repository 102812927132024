import React, { useState } from 'react';
import './CompanyNews.css';

const initialNews = [
  {
    id: 1,
    title: 'Harmosoft Launches New Product',
    date: '2024-06-15',
    content: 'We are excited to announce the launch of our new product, which offers innovative features and unparalleled performance. This product is designed to revolutionize the industry and provide our customers with the best experience possible. Stay tuned for more updates and detailed information about the product\'s features and benefits.',
  },
  {
    id: 2,
    title: 'Harmosoft Expands to New Markets',
    date: '2024-06-10',
    content: 'We are pleased to share that Harmosoft is expanding its operations to new international markets, bringing our solutions to a wider audience. This expansion is part of our strategic plan to grow our global presence and better serve our customers around the world. We are committed to providing top-notch solutions and support in these new markets.',
  },
];

function CompanyNews({ isAdmin }) {
  const [news, setNews] = useState(initialNews);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    content: '',
  });
  const [editingNews, setEditingNews] = useState(null);
  const [expandedId, setExpandedId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingNews) {
      setNews(news.map(item => (item.id === editingNews.id ? { ...editingNews, ...formData } : item)));
      setEditingNews(null);
    } else {
      setNews([...news, { ...formData, id: Date.now() }]);
    }
    setFormData({
      title: '',
      date: '',
      content: '',
    });
  };

  const handleEdit = (newsItem) => {
    setEditingNews(newsItem);
    setFormData(newsItem);
  };

  const handleDelete = (id) => {
    setNews(news.filter(item => item.id !== id));
  };

  const handleToggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <section className="company-news">
      <div className="container">
        <h2 className="news-headline">Company News</h2>
        <div className="news-list">
          {news.map((newsItem) => (
            <div className={`news-item ${expandedId === newsItem.id ? 'expanded' : ''}`} key={newsItem.id}>
              <h3 className="news-title">{newsItem.title}</h3>
              <p className="news-date">{newsItem.date}</p>
              <p className="news-content">
                {expandedId === newsItem.id ? newsItem.content : `${newsItem.content.substring(0, 100)}...`}
              </p>
              <button className="read-more-button" onClick={() => handleToggleExpand(newsItem.id)}>
                {expandedId === newsItem.id ? 'Read Less' : 'Read More'}
              </button>
              <a href={`/news/${newsItem.id}`} className="explore-more-link">Explore More</a>
              {isAdmin && (
                <div className="news-actions">
                  <button onClick={() => handleEdit(newsItem)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(newsItem.id)} className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
        {isAdmin && (
          <div className="news-form">
            <h3 className="form-headline">{editingNews ? 'Edit News' : 'Post New Update'}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                  id="content"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">{editingNews ? 'Update News' : 'Post News'}</button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}

export default CompanyNews;
