// src/components/academy/HarmosoftAcademy.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HarmosoftAcademy.css';

function HarmosoftAcademy() {
  return (
    <div className="harmosoft-academy">
      <section>
        <Link to="/academy/course-listings">Course Listings</Link>
      </section>
      <section>
        <Link to="/academy/course-details">Course Details</Link>
      </section>
      <section>
        <Link to="/academy/tutorials">Tutorials</Link>
      </section>
      <section>
        <Link to="/academy/webinars">Webinars</Link>
      </section>
      <section>
        <Link to="/academy/certifications">Certifications</Link>
      </section>
      <section>
        <Link to="/academy/instructors">Instructors</Link>
      </section>
      <section>
        <Link to="/academy/student-dashboard">Student Dashboard</Link>
      </section>
      <section>
        <Link to="/academy/enrollments">Enrollments</Link>
      </section>
      <section>
        <Link to="/academy/career-development">Career Development</Link>
      </section>
      <section>
        <Link to="/academy/contact">Academy Contact</Link>
      </section>
    </div>
  );
}

export default HarmosoftAcademy;
