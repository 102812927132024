// src/components/solutions/CombinedSolutions.js
import React from 'react';
import IndustrySolutions from './IndustrySolutions';
import TechnologySolutions from './TechnologySolutions';
import './CombinedSolutions.css';

function CombinedSolutions() {
  return (
    <div className="combined-solutions">
      <div className="solution-section">
        <IndustrySolutions />
      </div>
      <div className="solution-section">
        <TechnologySolutions />
      </div>
    </div>
  );
}

export default CombinedSolutions;
