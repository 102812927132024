import React, { useState } from 'react';
import './TechnologySolutions.css';

const technologySolutions = [
  {
    technology: 'Cloud Computing',
    description: `Our cloud computing solutions enable businesses to scale their operations, reduce costs, and enhance flexibility. We provide services that cover cloud migration, cloud-native application development, and cloud management.`,
    fullDescription: `Our cloud computing solutions enable businesses to scale their operations, reduce costs, and enhance flexibility. We provide services that cover cloud migration, cloud-native application development, and cloud management. Our disaster recovery solutions ensure business continuity and data protection in the event of unforeseen disruptions.`,
    solutions: [
      'Cloud Migration',
      'Cloud-Native Application Development',
      'Cloud Management and Optimization',
      'Disaster Recovery',
    ],
  },
  {
    technology: 'Artificial Intelligence',
    description: `Leverage the power of artificial intelligence to drive innovation and efficiency in your business. Our AI solutions include machine learning models, natural language processing, and computer vision applications.`,
    fullDescription: `Leverage the power of artificial intelligence to drive innovation and efficiency in your business. Our AI solutions include machine learning models, natural language processing, and computer vision applications. AI-powered chatbots enhance customer service and operational efficiency, providing automated responses and data-driven insights.`,
    solutions: [
      'Machine Learning Models',
      'Natural Language Processing',
      'Computer Vision',
      'AI-Powered Chatbots',
    ],
  },
  {
    technology: 'Blockchain',
    description: `Blockchain technology offers secure, transparent, and decentralized solutions for various industries. We provide blockchain development services, smart contracts, and blockchain integration.`,
    fullDescription: `Blockchain technology offers secure, transparent, and decentralized solutions for various industries. We provide blockchain development services, smart contracts, and blockchain integration. Our cryptocurrency solutions enable secure transactions and innovative financial applications.`,
    solutions: [
      'Blockchain Development',
      'Smart Contracts',
      'Blockchain Integration',
      'Cryptocurrency Solutions',
    ],
  },
  {
    technology: 'Internet of Things (IoT)',
    description: `Our IoT solutions help businesses connect and manage devices, gather valuable data, and improve operational efficiency. We offer IoT platform development, device management, and data analytics.`,
    fullDescription: `Our IoT solutions help businesses connect and manage devices, gather valuable data, and improve operational efficiency. We offer IoT platform development, device management, and data analytics. IoT security ensures that your connected devices and data are protected from cyber threats.`,
    solutions: [
      'IoT Platform Development',
      'Device Management',
      'Data Analytics',
      'IoT Security',
    ],
  },
];

function TechnologySolutions() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className="technology-solutions">
      <div className="container">
        <h2 className="technology-headline">Technology Solutions</h2>
        <div className="technology-list">
          {technologySolutions.map((techSolution, index) => (
            <div
              className={`technology-item ${expandedIndex === index ? 'expanded' : ''}`}
              key={index}
              onMouseEnter={() => setExpandedIndex(index)}
              onMouseLeave={() => setExpandedIndex(null)}
            >
              <h3 className="technology-title">{techSolution.technology}</h3>
              <p className="technology-description">
                {expandedIndex === index ? techSolution.fullDescription : techSolution.description}
              </p>
              {expandedIndex === index && (
                <button onClick={() => toggleReadMore(index)} className="read-more">
                  {expandedIndex === index ? 'Read Less' : 'Read More'}
                </button>
              )}
              <ul className="technology-solutions-list">
                {techSolution.solutions.map((solution, solutionIndex) => (
                  <li key={solutionIndex} className="technology-solution">
                    <a href={`/solutions/${techSolution.technology.toLowerCase()}#${solution.toLowerCase().replace(/\s+/g, '-')}`}>
                      {solution}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default TechnologySolutions;
