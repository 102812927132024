import React, { useState, useEffect, useRef } from 'react';
import './BlogPost.css';

const initialPosts = [
  {
    id: 1,
    title: 'Understanding React Hooks',
    date: '2024-06-15',
    content: 'React Hooks provide a way to use state and other React features without writing a class. In this post, we will explore the basics of React Hooks and how to use them in your projects. React Hooks are a game-changer in React development, offering a more functional approach to managing state and side effects in your applications. By the end of this article, you will have a solid understanding of how to use Hooks in your React projects.',
    color: '#FFC107', // Amber
    textColor: '#000000',
  },
  {
    id: 2,
    title: 'Introduction to Node.js',
    date: '2024-06-12',
    content: 'Node.js is a powerful JavaScript runtime built on Chrome\'s V8 engine. This post covers the fundamentals of Node.js and how to get started with building server-side applications. Node.js allows developers to use JavaScript to write server-side code, making it a versatile tool for creating full-stack applications. We will cover the basics of setting up a Node.js environment and writing your first server-side code.',
    color: '#03A9F4', // Light Blue
    textColor: '#FFFFFF',
  },
  {
    id: 3,
    title: 'CSS Grid Layout: A Complete Guide',
    date: '2024-06-10',
    content: 'CSS Grid Layout is a two-dimensional layout system for the web. It lets you layout items in rows and columns, making complex layouts simple to create. This guide will walk you through the basics of CSS Grid Layout and how to use it in your web projects. By understanding CSS Grid, you can create flexible and responsive layouts with ease.',
    color: '#4CAF50', // Green
    textColor: '#FFFFFF',
  },
  {
    id: 4,
    title: 'JavaScript ES6 Features',
    date: '2024-06-08',
    content: 'ES6 introduced many new features to JavaScript, including arrow functions, classes, template literals, and more. This post explores the key features of ES6 and how to use them in your projects. ES6, also known as ECMAScript 2015, brought significant improvements to JavaScript, making it more powerful and easier to work with. Learn how to leverage these new features to write cleaner and more efficient code.',
    color: '#FF5722', // Deep Orange
    textColor: '#FFFFFF',
  },
  {
    id: 5,
    title: 'Building RESTful APIs with Express.js',
    date: '2024-06-05',
    content: 'Express.js is a minimal and flexible Node.js web application framework. In this post, we will learn how to build RESTful APIs using Express.js. RESTful APIs are a standard way of creating web services that are easy to use and maintain. With Express.js, you can create robust and scalable APIs for your web applications.',
    color: '#9C27B0', // Purple
    textColor: '#FFFFFF',
  },
];

function BlogPost({ isAdmin }) {
  const [posts, setPosts] = useState(initialPosts);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    content: '',
  });
  const [editingPost, setEditingPost] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  
  const scrollRef = useRef(null);
  const scrollBarRef = useRef(null);

  useEffect(() => {
    const syncScroll = () => {
      if (scrollBarRef.current && scrollRef.current) {
        scrollRef.current.scrollLeft = scrollBarRef.current.scrollLeft;
      }
    };

    const syncScrollBar = () => {
      if (scrollBarRef.current && scrollRef.current) {
        scrollBarRef.current.scrollLeft = scrollRef.current.scrollLeft;
      }
    };

    if (scrollRef.current && scrollBarRef.current) {
      scrollBarRef.current.addEventListener('scroll', syncScroll);
      scrollRef.current.addEventListener('scroll', syncScrollBar);
    }

    return () => {
      if (scrollBarRef.current && scrollRef.current) {
        scrollBarRef.current.removeEventListener('scroll', syncScroll);
        scrollRef.current.removeEventListener('scroll', syncScrollBar);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingPost) {
      setPosts(posts.map(post => (post.id === editingPost.id ? { ...editingPost, ...formData } : post)));
      setEditingPost(null);
    } else {
      setPosts([...posts, { ...formData, id: Date.now() }]);
    }
    setFormData({
      title: '',
      date: '',
      content: '',
    });
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setFormData(post);
  };

  const handleDelete = (id) => {
    setPosts(posts.filter(post => post.id !== id));
  };

  const handleToggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <section className="blog-post">
      <div className="container">
        <h2 className="blog-headline">Blog Posts</h2>
        <div className="carousel">
          <button className="carousel-button left" onClick={() => document.querySelector('.post-list').scrollBy({ left: -300, behavior: 'smooth' })}>&lt;</button>
          <div className="post-list" ref={scrollRef}>
            {posts.map((post) => (
              <div
                className={`post-item ${expandedId === post.id ? 'expanded' : ''}`}
                key={post.id}
                style={{ backgroundColor: post.color, color: post.textColor }}
              >
                <h3 className="post-title">{post.title}</h3>
                <p className="post-date">{post.date}</p>
                <p className="post-content">
                  {expandedId === post.id ? post.content : `${post.content.substring(0, 100)}...`}
                </p>
                <button className="read-more-button" onClick={() => handleToggleExpand(post.id)}>
                  {expandedId === post.id ? 'Read Less' : 'Read More'}
                </button>
                <a href={`/blog/${post.id}`} className="explore-more-link">Explore More</a>
                {isAdmin && (
                  <div className="post-actions">
                    <button onClick={() => handleEdit(post)} className="edit-button">Edit</button>
                    <button onClick={() => handleDelete(post.id)} className="delete-button">Delete</button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <button className="carousel-button right" onClick={() => document.querySelector('.post-list').scrollBy({ left: 300, behavior: 'smooth' })}>&gt;</button>
        </div>
        <div className="scroll-bar" ref={scrollBarRef}>
          <div className="scroll-bar-content"></div>
        </div>
        {isAdmin && (
          <div className="post-form">
            <h3 className="form-headline">{editingPost ? 'Edit Post' : 'New Post'}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                  id="content"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">{editingPost ? 'Update Post' : 'Publish Post'}</button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}

export default BlogPost;
