import React, { useState } from 'react';
import './Whitepapers.css';

const initialWhitepapers = [
  {
    id: 1,
    title: 'The Future of AI in Business',
    date: '2024-06-01',
    link: '/docs/future-of-ai.pdf',
  },
  {
    id: 2,
    title: 'Blockchain Technology Explained',
    date: '2024-06-10',
    link: '/docs/blockchain-explained.pdf',
  },
  {
    id: 3,
    title: 'The Impact of Quantum Computing',
    date: '2024-05-15',
    link: '/docs/quantum-computing.pdf',
  },
  {
    id: 4,
    title: 'Cybersecurity in the Modern World',
    date: '2024-04-20',
    link: '/docs/cybersecurity.pdf',
  },
  {
    id: 5,
    title: 'Advancements in Machine Learning',
    date: '2024-03-10',
    link: '/docs/machine-learning.pdf',
  },
  {
    id: 6,
    title: 'IoT: Connecting the World',
    date: '2024-02-05',
    link: '/docs/iot.pdf',
  },
  {
    id: 7,
    title: '5G Technology and its Benefits',
    date: '2024-01-25',
    link: '/docs/5g-technology.pdf',
  },
  {
    id: 8,
    title: 'Big Data Analytics in Business',
    date: '2024-01-10',
    link: '/docs/big-data.pdf',
  },
  {
    id: 9,
    title: 'Cloud Computing: A Comprehensive Guide',
    date: '2023-12-15',
    link: '/docs/cloud-computing.pdf',
  },
  {
    id: 10,
    title: 'The Role of AI in Healthcare',
    date: '2023-12-01',
    link: '/docs/ai-healthcare.pdf',
  },
];

function Whitepapers({ isAdmin }) {
  const [whitepapers, setWhitepapers] = useState(initialWhitepapers);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    link: '',
  });
  const [editingPaper, setEditingPaper] = useState(null);
  const [messageVisible, setMessageVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingPaper) {
      setWhitepapers(
        whitepapers.map((paper) =>
          paper.id === editingPaper.id ? { ...editingPaper, ...formData } : paper
        )
      );
      setEditingPaper(null);
    } else {
      setWhitepapers([...whitepapers, { ...formData, id: Date.now() }]);
    }
    setFormData({
      title: '',
      date: '',
      link: '',
    });
  };

  const handleEdit = (paper) => {
    setEditingPaper(paper);
    setFormData(paper);
  };

  const handleDelete = (id) => {
    setWhitepapers(whitepapers.filter((paper) => paper.id !== id));
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();
    setMessageVisible(true);
  };

  const closeMessage = () => {
    setMessageVisible(false);
  };

  const scrollLeft = () => {
    document.querySelector('.whitepapers-list').scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    document.querySelector('.whitepapers-list').scrollBy({ left: 300, behavior: 'smooth' });
  };

  return (
    <section className="whitepapers">
      <div className="container">
        <h2 className="whitepapers-headline">Whitepapers</h2>
        <div className="carousel-controls">
          <button className="carousel-button left" onClick={scrollLeft}>
            &#10094;
          </button>
          <button className="carousel-button right" onClick={scrollRight}>
            &#10095;
          </button>
        </div>
        <div className="whitepapers-list">
          {whitepapers.map((paper) => (
            <div className="whitepaper-item" key={paper.id}>
              <h3 className="whitepaper-title">{paper.title}</h3>
              <p className="whitepaper-date">{paper.date}</p>
              <a href={paper.link} className="whitepaper-link" onClick={handleDownloadClick}>
                Download
              </a>
              {isAdmin && (
                <div className="whitepaper-actions">
                  <button onClick={() => handleEdit(paper)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(paper.id)} className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
        {isAdmin && (
          <div className="whitepaper-form">
            <h3 className="form-headline">{editingPaper ? 'Edit Whitepaper' : 'New Whitepaper'}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="link">Link</label>
                <input
                  type="text"
                  id="link"
                  name="link"
                  value={formData.link}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="submit-button">{editingPaper ? 'Update Whitepaper' : 'Post Whitepaper'}</button>
            </form>
          </div>
        )}
        {messageVisible && (
          <div className="message-overlay">
            <div className="message-box">
              <h2 className="message-title">Notice</h2>
              <p className="message-content">
                This document is not yet updated and will be available soon. We are currently working on our website to improve your experience and provide up-to-date information. Please check back later for the latest documents.
              </p>
              <button className="message-close-button" onClick={closeMessage}>Close</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Whitepapers;
