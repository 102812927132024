import React, { useState } from 'react';
import './CaseStudies.css';

const caseStudies = [
  {
    title: 'Digital Transformation for Healthcare Provider',
    client: 'Medico Health Systems',
    description: 'We helped Medico Health Systems transition to a fully digital operation, enhancing patient care and operational efficiency.',
    details: `Medico Health Systems faced challenges in managing patient records and operational efficiency. We developed a comprehensive digital transformation strategy that included:
    - Implementation of Electronic Health Records (EHR)
    - Development of a patient portal for better engagement
    - Integration with existing medical devices and systems
    The project resulted in improved patient care, streamlined operations, and enhanced data security.`,
  },
  {
    title: 'E-commerce Platform Development for Retail Giant',
    client: 'ShopEase',
    description: 'We developed a robust e-commerce platform for ShopEase, enabling them to scale their operations and reach a wider audience.',
    details: `ShopEase needed a scalable e-commerce platform to manage their expanding operations. Our team provided:
    - Custom e-commerce platform development
    - Integration with payment gateways and logistics partners
    - Advanced analytics and reporting tools
    The new platform allowed ShopEase to handle increased traffic, improved customer experience, and boosted sales.`,
  },
  {
    title: 'Cloud Migration for Financial Services Firm',
    client: 'FinTrust',
    description: 'We successfully migrated FinTrust’s legacy systems to the cloud, ensuring enhanced security and performance.',
    details: `FinTrust’s on-premise systems were becoming costly and difficult to maintain. Our cloud solutions team executed a seamless cloud migration, which involved:
    - Detailed assessment of existing systems
    - Planning and execution of cloud migration
    - Post-migration optimization and support
    The migration resulted in improved system performance, scalability, and reduced operational costs for FinTrust.`,
  },
];

function CaseStudies() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className="case-studies">
      <div className="container">
        <h2 className="case-studies-headline">Case Studies</h2>
        {caseStudies.map((caseStudy, index) => (
          <div className={`case-study-item ${expandedIndex === index ? 'expanded' : ''}`} key={index}>
            <h3 className="case-study-title">{caseStudy.title}</h3>
            <p className="case-study-client"><strong>Client:</strong> {caseStudy.client}</p>
            <p className="case-study-description">
              {expandedIndex === index ? caseStudy.details : caseStudy.description}
            </p>
            <button onClick={() => toggleReadMore(index)} className="read-more">
              {expandedIndex === index ? 'Read Less' : 'Read More'}
            </button>
          </div>
        ))}
      </div>
    </section>
  );
}

export default CaseStudies;
