// src/components/academy/Instructors.js
import React from 'react';
import './Instructors.css';

const instructorsData = [
  { id: 1, name: 'Fidel Omondi', expertise: 'Full Stack Development', profileLink: '/academy/instructors/1' },
  { id: 2, name: 'Jane Smith', expertise: 'Data Science and Machine Learning', profileLink: '/academy/instructors/2' },
  { id: 3, name: 'Emily Johnson', expertise: 'Cybersecurity', profileLink: '/academy/instructors/3' },
  { id: 4, name: 'Michael Brown', expertise: 'DevOps Engineering', profileLink: '/academy/instructors/4' },
  { id: 5, name: 'Jessica Davis', expertise: 'Mobile App Development', profileLink: '/academy/instructors/5' },
  { id: 6, name: 'David Wilson', expertise: 'Cloud Computing', profileLink: '/academy/instructors/6' },
  { id: 7, name: 'Linda Martinez', expertise: 'AI and Deep Learning', profileLink: '/academy/instructors/7' },
  { id: 8, name: 'Chris Taylor', expertise: 'Blockchain Development', profileLink: '/academy/instructors/8' },
  { id: 9, name: 'Sarah Anderson', expertise: 'Frontend Development', profileLink: '/academy/instructors/9' },
  { id: 10, name: 'James Thomas', expertise: 'Big Data Analytics', profileLink: '/academy/instructors/10' },
];

function Instructors({ isAdmin }) {
  return (
    <section className="instructors">
      <div className="container">
        <h2 className="headline">Instructors</h2>
        <div className="instructor-list">
          {instructorsData.map((instructor) => (
            <div className="instructor-item" key={instructor.id}>
              <h3 className="instructor-name">{instructor.name}</h3>
              <p className="instructor-expertise">{instructor.expertise}</p>
              <a href={instructor.profileLink} className="profile-link">View Profile</a>
              {isAdmin && (
                <div className="instructor-actions">
                  <button className="edit-button">Edit</button>
                  <button className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Instructors;
