import React, { useState } from 'react';
import './IndustrySolutions.css';

const industrySolutions = [
  {
    industry: 'Healthcare',
    description: `Our healthcare solutions are designed to improve patient care, streamline operations, and ensure regulatory compliance.`,
    fullDescription: `From electronic health records (EHR) to telemedicine platforms, we provide technology that enhances the efficiency and effectiveness of healthcare providers. Our healthcare solutions are tailored to meet the unique needs of healthcare institutions, ensuring better patient outcomes and operational efficiency.`,
    solutions: [
      'Electronic Health Records (EHR)',
      'Telemedicine Platforms',
      'Patient Management Systems',
      'Medical Billing Software',
    ],
  },
  {
    industry: 'Finance',
    description: `We offer robust financial technology solutions that help institutions manage risk, ensure compliance, and improve customer experience.`,
    fullDescription: `Our services include blockchain technology, fraud detection systems, and custom financial software development. We provide comprehensive solutions that empower financial institutions to stay ahead in a rapidly evolving industry.`,
    solutions: [
      'Blockchain Technology',
      'Fraud Detection Systems',
      'Custom Financial Software',
      'Automated Trading Platforms',
    ],
  },
  {
    industry: 'Retail',
    description: `Our retail solutions help businesses enhance customer engagement, optimize inventory management, and streamline point-of-sale processes.`,
    fullDescription: `From e-commerce platforms to loyalty programs, we provide the tools needed to succeed in a competitive market. Our solutions are designed to enhance the customer experience, improve operational efficiency, and drive sales growth.`,
    solutions: [
      'E-commerce Platforms',
      'Inventory Management Systems',
      'Point-of-Sale Solutions',
      'Customer Loyalty Programs',
    ],
  },
  {
    industry: 'Manufacturing',
    description: `Our manufacturing solutions are designed to improve production efficiency, reduce costs, and ensure quality control.`,
    fullDescription: `We offer services such as supply chain management systems, automation solutions, and predictive maintenance. Our solutions help manufacturers optimize their operations, enhance product quality, and achieve greater efficiency.`,
    solutions: [
      'Supply Chain Management',
      'Automation Solutions',
      'Predictive Maintenance',
      'Quality Control Systems',
    ],
  },
];

function IndustrySolutions() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className="industry-solutions">
      <div className="container">
        <h2 className="industry-headline">Industry Solutions</h2>
        <div className="industry-list">
          {industrySolutions.map((industrySolution, index) => (
            <div
              className={`industry-item ${expandedIndex === index ? 'expanded' : ''}`}
              key={index}
              onMouseEnter={() => setExpandedIndex(index)}
              onMouseLeave={() => setExpandedIndex(null)}
            >
              <h3 className="industry-title">{industrySolution.industry}</h3>
              <p className="industry-description">
                {expandedIndex === index ? industrySolution.fullDescription : industrySolution.description}
              </p>
              {expandedIndex === index && (
                <button onClick={() => toggleReadMore(index)} className="read-more">
                  {expandedIndex === index ? 'Read Less' : 'Read More'}
                </button>
              )}
              <ul className="industry-solutions-list">
                {industrySolution.solutions.map((solution, solutionIndex) => (
                  <li key={solutionIndex} className="industry-solution">
                    <a href={`/solutions/${industrySolution.industry.toLowerCase()}#${solution.toLowerCase().replace(/\s+/g, '-')}`}>
                      {solution}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default IndustrySolutions;
