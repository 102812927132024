// src/components/academy/AcademyContact.js
import React from 'react';
import './AcademyContact.css';

function AcademyContact() {
  return (
    <section className="academy-contact">
      <div className="container">
        <h2 className="contact-headline">Contact Harmosoft Academy</h2>
        <div className="contact-content">
          <div className="contact-details">
            <p><strong>Email:</strong> <a href="mailto:academy@harmosoft.co.ke">academy@harmosoft.co.ke</a></p>
            {/* <p><strong>Phone:</strong> <a href="tel:+254700123456">+254 700 123 456</a></p> */}
            <p><strong>Office Address:</strong> Watermark Business Park, Karen, Nairobi, Kenya</p>
          </div>
          <form className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AcademyContact;
