// src/components/academy/CourseDetails.js
import React, { useState } from 'react';
import './CourseDetails.css';

const courseData = [
  { 
    id: 1, 
    title: 'Full Stack Web Development with React and Node.js', 
    date: '2024-07-01', 
    description: 'Learn how to build full stack web applications using React, Node.js, Express, and MongoDB.', 
    details: `This course covers both front-end and back-end development, providing a comprehensive understanding of full stack web development. You will start with the basics of HTML, CSS, and JavaScript, then move on to advanced topics like React for building dynamic user interfaces, Node.js and Express for server-side development, and MongoDB for database management. By the end of the course, you will be able to build, test, and deploy a complete web application.` 
  },
  { 
    id: 2, 
    title: 'Advanced JavaScript and ES6', 
    date: '2024-06-25', 
    description: 'Deep dive into modern JavaScript features including ES6, async/await, and more.', 
    details: `Explore the latest features in JavaScript, including ES6 syntax, async/await, promises, and advanced concepts. This course will cover topics such as destructuring, spread/rest operators, classes, modules, and modern JavaScript best practices. You will also learn about asynchronous programming with promises and async/await, which are essential for handling complex asynchronous tasks in modern web development.` 
  },
  { 
    id: 3, 
    title: 'Microservices Architecture with Docker and Kubernetes', 
    date: '2024-06-20', 
    description: 'Learn how to build, deploy, and manage microservices using Docker and Kubernetes.', 
    details: `Understand the principles of microservices architecture and how to implement them using Docker and Kubernetes. This course covers the fundamentals of containerization with Docker, orchestration with Kubernetes, and best practices for designing, deploying, and managing scalable and resilient microservices. You will gain hands-on experience in containerizing applications, creating and managing Kubernetes clusters, and implementing CI/CD pipelines for automated deployment.` 
  },
  { 
    id: 4, 
    title: 'Data Science and Machine Learning with Python', 
    date: '2024-06-15', 
    description: 'Master data science and machine learning techniques using Python and popular libraries.', 
    details: `This course includes comprehensive training on data manipulation, analysis, and visualization using Python. You will learn how to use libraries like Pandas, NumPy, and Matplotlib for data analysis and visualization, and Scikit-Learn for building and evaluating machine learning models. Topics include data preprocessing, feature engineering, model selection, hyperparameter tuning, and deploying machine learning models.` 
  },
  { 
    id: 5, 
    title: 'Mobile App Development with React Native', 
    date: '2024-06-10', 
    description: 'Learn how to build cross-platform mobile applications using React Native.', 
    details: `Develop mobile applications that work on both Android and iOS using the React Native framework. This course will cover the basics of React Native, including components, navigation, state management, and APIs. You will also learn how to use libraries like Redux for state management and how to integrate native modules for accessing device-specific features. By the end of the course, you will have built and deployed a fully functional mobile app.` 
  },
  { 
    id: 6, 
    title: 'DevOps Engineering with AWS and Terraform', 
    date: '2024-06-05', 
    description: 'Get hands-on experience with DevOps practices and tools using AWS and Terraform.', 
    details: `Learn how to automate infrastructure and deployment processes using AWS services and Terraform. This course covers the basics of cloud computing with AWS, infrastructure as code (IaC) with Terraform, and DevOps practices like continuous integration and continuous deployment (CI/CD). You will learn how to provision and manage AWS resources using Terraform, set up CI/CD pipelines with tools like Jenkins, and implement monitoring and logging solutions.` 
  },
  { 
    id: 7, 
    title: 'Cybersecurity Fundamentals', 
    date: '2024-06-01', 
    description: 'Learn the fundamentals of cybersecurity, including risk management, encryption, and network security.', 
    details: `Understand the basics of cybersecurity and how to protect systems from cyber threats. This course covers topics such as risk management, cryptography, network security, security policies and protocols, and incident response. You will learn about common security vulnerabilities and how to mitigate them, as well as best practices for securing systems and data.` 
  },
  { 
    id: 8, 
    title: 'AI and Deep Learning with TensorFlow', 
    date: '2024-05-25', 
    description: 'Master the basics of AI and deep learning using TensorFlow and Keras.', 
    details: `Get hands-on experience with AI and deep learning techniques using TensorFlow and Keras libraries. This course covers the fundamentals of neural networks, deep learning architectures, and popular frameworks like TensorFlow and Keras. You will learn how to build, train, and deploy deep learning models for tasks such as image recognition, natural language processing, and time series forecasting.` 
  },
  { 
    id: 9, 
    title: 'Blockchain Development with Ethereum', 
    date: '2024-05-20', 
    description: 'Learn how to develop decentralized applications (DApps) on the Ethereum blockchain.', 
    details: `Understand the principles of blockchain technology and how to build DApps on the Ethereum platform. This course covers the basics of blockchain, smart contracts, and decentralized applications. You will learn how to write smart contracts using Solidity, interact with the Ethereum blockchain using Web3.js, and build decentralized applications that run on the Ethereum network.` 
  },
  { 
    id: 10, 
    title: 'Cloud Computing with Microsoft Azure', 
    date: '2024-05-15', 
    description: 'Gain expertise in cloud computing services and solutions using Microsoft Azure.', 
    details: `Learn how to deploy and manage cloud applications using Microsoft Azure services. This course covers the basics of cloud computing, Azure infrastructure, and services like virtual machines, storage, databases, and networking. You will also learn about Azure DevOps, monitoring, and security, and how to implement best practices for cloud architecture and management.` 
  },
  { 
    id: 11, 
    title: 'Backend Development with Django and Python', 
    date: '2024-05-10', 
    description: 'Learn how to build robust backend systems using Django and Python.', 
    details: `Develop scalable backend systems using the Django framework and Python programming language. This course covers the basics of Django, including models, views, templates, and RESTful APIs. You will learn how to build, test, and deploy backend applications with Django, and how to integrate them with front-end applications and databases.` 
  },
  { 
    id: 12, 
    title: 'Frontend Development with Vue.js', 
    date: '2024-05-05', 
    description: 'Master the fundamentals of frontend development using Vue.js.', 
    details: `Understand the core concepts of Vue.js and how to build interactive web interfaces. This course covers the basics of Vue.js, including components, directives, state management, and routing. You will learn how to build dynamic and responsive web applications using Vue.js, and how to integrate them with backend APIs and services.` 
  },
  { 
    id: 13, 
    title: 'Continuous Integration and Continuous Deployment (CI/CD)', 
    date: '2024-05-01', 
    description: 'Learn how to implement CI/CD pipelines using Jenkins and GitHub Actions.', 
    details: `Automate the build, test, and deployment processes using CI/CD tools like Jenkins and GitHub Actions. This course covers the basics of continuous integration and continuous deployment, including setting up CI/CD pipelines, configuring build and test environments, and automating deployment to various environments. You will learn how to use Jenkins and GitHub Actions to streamline the software development process and ensure high-quality code.` 
  },
  { 
    id: 14, 
    title: 'Big Data Analytics with Hadoop and Spark', 
    date: '2024-04-25', 
    description: 'Get hands-on experience with big data processing and analytics using Hadoop and Spark.', 
    details: `Learn how to process and analyze large datasets using Hadoop and Spark frameworks. This course covers the basics of big data, distributed computing, and data processing with Hadoop and Spark. You will learn how to set up and manage Hadoop clusters, write MapReduce jobs, and use Spark for real-time data processing and analytics. The course also covers data storage and management with HDFS, Hive, and HBase.` 
  },
  { 
    id: 15, 
    title: 'UI/UX Design Principles', 
    date: '2024-04-20', 
    description: 'Learn the principles of UI/UX design and how to create user-friendly interfaces.', 
    details: `Understand the key principles of user interface and user experience design to create intuitive applications. This course covers the basics of UI/UX design, including user research, wireframing, prototyping, and usability testing. You will learn how to design user-centered interfaces that are both visually appealing and easy to use, and how to apply design thinking to solve complex design challenges.` 
  },
  { 
    id: 16, 
    title: 'Agile Project Management with Scrum', 
    date: '2024-04-15', 
    description: 'Understand the principles of Agile project management and how to apply Scrum methodologies.', 
    details: `Learn how to manage projects using Agile methodologies and the Scrum framework. This course covers the basics of Agile project management, including Scrum roles, artifacts, and events. You will learn how to plan and execute projects in iterative sprints, manage backlogs, and use tools like Jira for tracking progress. The course also covers best practices for Agile team collaboration and communication.` 
  },
  { 
    id: 17, 
    title: 'Introduction to Artificial Intelligence', 
    date: '2024-04-10', 
    description: 'Explore the basics of artificial intelligence and its applications across various industries.', 
    details: `Get an overview of AI concepts and how they are applied in different industries. This course covers the fundamentals of artificial intelligence, including machine learning, natural language processing, computer vision, and robotics. You will learn about the different types of AI, their applications, and the ethical considerations of AI development and deployment.` 
  },
  { 
    id: 18, 
    title: 'Internet of Things (IoT) Development', 
    date: '2024-04-05', 
    description: 'Learn how to develop IoT applications and integrate them with cloud services.', 
    details: `Understand the principles of IoT and how to develop applications that connect with cloud services. This course covers the basics of IoT, including sensors, communication protocols, and data processing. You will learn how to develop IoT applications using platforms like Arduino and Raspberry Pi, and how to integrate them with cloud services for data storage, analysis, and visualization.` 
  },
  { 
    id: 19, 
    title: 'Network Engineering and Management', 
    date: '2024-04-01', 
    description: 'Gain knowledge in network engineering, including design, management, and troubleshooting.', 
    details: `Learn about network design, management, and troubleshooting techniques. This course covers the basics of network engineering, including network topologies, protocols, and security. You will learn how to design and implement networks, configure routers and switches, and troubleshoot network issues. The course also covers network monitoring and management tools and techniques.` 
  },
  { 
    id: 20, 
    title: 'Programming with Python for Beginners', 
    date: '2024-03-25', 
    description: 'Learn the basics of programming with Python, one of the most popular programming languages.', 
    details: `Get started with Python programming and understand its applications. This course covers the basics of Python, including syntax, data types, and control structures. You will learn how to write simple programs, work with libraries, and develop applications using Python. The course also covers best practices for writing clean and efficient code, as well as debugging and testing techniques.` 
  },
  { 
    id: 21, 
    title: 'Modern Web Design with HTML, CSS, and JavaScript', 
    date: '2024-03-20', 
    description: 'Master modern web design principles using HTML, CSS, and JavaScript.', 
    details: `Learn how to design and build modern websites using HTML, CSS, and JavaScript. This course covers the basics of web design, including responsive design, accessibility, and performance optimization. You will learn how to create visually appealing and user-friendly web pages using HTML for structure, CSS for styling, and JavaScript for interactivity. The course also covers best practices for web development and the latest web technologies and frameworks.` 
  }
];

function CourseDetails() {
  const [expandedCourses, setExpandedCourses] = useState([]);

  const toggleCourseDetails = (courseId) => {
    setExpandedCourses((prevExpandedCourses) =>
      prevExpandedCourses.includes(courseId)
        ? prevExpandedCourses.filter((id) => id !== courseId)
        : [...prevExpandedCourses, courseId]
    );
  };

  return (
    <section className="course-details">
      <div className="container">
        <h2 className="headline">Course Details</h2>
        <div className="course-list">
          {courseData.map((course) => (
            <div className="course-item" key={course.id}>
              <h3 className="course-title">{course.title}</h3>
              <p className="course-date">{course.date}</p>
              <p className="course-description">
                {expandedCourses.includes(course.id)
                  ? course.details
                  : `${course.details.substring(0, 100)}...`}
              </p>
              <button
                className="toggle-details-button"
                onClick={() => toggleCourseDetails(course.id)}
              >
                {expandedCourses.includes(course.id) ? 'Read less' : 'Read more'}
              </button>
              <button className="register-button">Register for this course</button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CourseDetails;
