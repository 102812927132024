import React, { useState } from 'react';
import './IndustryNews.css';

const initialIndustryNews = [
  {
    id: 1,
    title: 'Latest Tech Trends in 2024',
    date: '2024-06-14',
    content: 'Explore the latest trends in technology for 2024, including advancements in AI, blockchain, and IoT.',
  },
  {
    id: 2,
    title: 'Impact of AI on Various Industries',
    date: '2024-06-10',
    content: 'AI is transforming industries across the board. Read about the significant impacts and future prospects of AI technology.',
  },
];

function IndustryNews({ isAdmin }) {
  const [industryNews, setIndustryNews] = useState(initialIndustryNews);
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    content: '',
  });
  const [editingNews, setEditingNews] = useState(null);
  const [expandedId, setExpandedId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingNews) {
      setIndustryNews(
        industryNews.map((newsItem) =>
          newsItem.id === editingNews.id ? { ...editingNews, ...formData } : newsItem
        )
      );
      setEditingNews(null);
    } else {
      setIndustryNews([...industryNews, { ...formData, id: Date.now() }]);
    }
    setFormData({
      title: '',
      date: '',
      content: '',
    });
  };

  const handleEdit = (newsItem) => {
    setEditingNews(newsItem);
    setFormData(newsItem);
  };

  const handleDelete = (id) => {
    setIndustryNews(industryNews.filter((newsItem) => newsItem.id !== id));
  };

  const handleToggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <section className="industry-news">
      <div className="container">
        <h2 className="news-headline">Industry News</h2>
        <div className="news-list">
          {industryNews.map((newsItem) => (
            <div
              className={`news-item ${expandedId === newsItem.id ? 'expanded' : ''}`}
              key={newsItem.id}
            >
              <h3 className="news-title">{newsItem.title}</h3>
              <p className="news-date">{newsItem.date}</p>
              <p className="news-content">
                {expandedId === newsItem.id
                  ? newsItem.content
                  : `${newsItem.content.substring(0, 100)}...`}
              </p>
              <button className="read-more-button" onClick={() => handleToggleExpand(newsItem.id)}>
                {expandedId === newsItem.id ? 'Read Less' : 'Read More'}
              </button>
              <a href={`/industry-news/${newsItem.id}`} className="explore-more-link">
                Explore More
              </a>
              {isAdmin && (
                <div className="news-actions">
                  <button onClick={() => handleEdit(newsItem)} className="edit-button">
                    Edit
                  </button>
                  <button onClick={() => handleDelete(newsItem.id)} className="delete-button">
                    Delete
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        {isAdmin && (
          <div className="news-form">
            <h3 className="form-headline">{editingNews ? 'Edit News' : 'Post Industry News'}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                  id="content"
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button type="submit" className="submit-button">
                {editingNews ? 'Update News' : 'Post News'}
              </button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}

export default IndustryNews;
