import React, { useState } from 'react';
import './Team.css';

const teamOverview = {
  roles: [
    { title: 'Full-Stack Development', description: 'Developing end-to-end solutions using cutting-edge technologies.' },
    { title: 'Cloud & DevOps Engineering', description: 'Managing cloud environments and automating workflows.' },
    { title: 'Data Science & Machine Learning', description: 'Leveraging data to drive insights and build predictive models.' },
    { title: 'Cybersecurity & Risk Management', description: 'Ensuring robust security and risk mitigation for digital assets.' },
    { title: 'UI/UX Design & Development', description: 'Crafting intuitive and engaging user experiences.' },
    { title: 'Blockchain & Smart Contracts', description: 'Building decentralized applications and smart contracts.' },
    { title: 'AI & Natural Language Processing', description: 'Creating intelligent systems using AI and NLP technologies.' },
    { title: 'Augmented & Virtual Reality', description: 'Designing immersive AR and VR experiences.' },
    { title: 'IoT & Embedded Systems', description: 'Developing connected solutions and integrating hardware.' },
    { title: 'Quantum Computing', description: 'Exploring and implementing quantum computing technologies.' },
    { title: 'Robotics & Automation', description: 'Building robotic systems and automation solutions.' },
    { title: 'Digital Marketing & SEO', description: 'Optimizing online presence and marketing strategies.' },
    { title: 'Virtualization & Containerization', description: 'Using containers and virtualization for scalable environments.' },
    { title: 'E-commerce & FinTech Solutions', description: 'Creating and enhancing digital commerce and financial systems.' },
    { title: 'Healthcare IT & Telemedicine', description: 'Developing IT solutions for healthcare and telemedicine.' },
    { title: 'Environmental Tech & Sustainability', description: 'Innovating technologies for sustainability and energy efficiency.' },
  ],
};

function Team() {
  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleFlip = (index) => {
    setFlippedIndex(flippedIndex === index ? null : index);
  };

  return (
    <section className="team">
      <div className="container">
        <h2 className="team-headline">Our Team</h2>
        <div className="team-overview">
          <p>
          Harmosoft, founded by Dr. Jusper Wendo and other esteemed co-founders, is driven by a highly skilled and dedicated team of professionals. Our team's expertise spans modern and cutting-edge technologies, ensuring we remain at the forefront of industry trends. Our areas of expertise include:
          </p>
          <ul className="roles-list">
            {teamOverview.roles.map((role, index) => (
              <li 
                key={index} 
                className={`role-item ${flippedIndex === index ? 'flipped' : ''}`} 
                onMouseEnter={() => handleFlip(index)} 
                onMouseLeave={() => handleFlip(index)}
              >
                <div className="inner">
                  <div className="front">
                    <strong>{role.title}</strong>
                  </div>
                  <div className="back">
                    {role.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Team;
