import React from 'react';
import CompanyHistory from './CompanyHistory';
import VisionMission from './VisionMission';
import Team from './Team';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us">
      <div className="about-us-header">
        <h1>About Us</h1>
        {/* <p>Learn more about our history, vision, mission, and the team that makes everything possible.</p> */}
      </div>
      <div className="about-us-content">
        <div className="about-us-section">
          <CompanyHistory />
        </div>
        <div className="about-us-section">
          <VisionMission />
        </div>
        <div className="about-us-section">
          <Team />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
