// src/components/academy/StudentDashboard.js
import React, { useState } from 'react';
import { FaUser, FaBook, FaGraduationCap, FaCalendarAlt, FaBell, FaChartLine, FaClipboardList, FaComments, FaClipboardCheck, FaProjectDiagram, FaCog } from 'react-icons/fa';
import './StudentDashboard.css';

const StudentDashboard = ({ isAdmin }) => {
  const [selectedSection, setSelectedSection] = useState('student-info');

  const sections = [
    {
      id: 'student-info',
      title: 'Student Information',
      icon: <FaUser />,
      content: (
        <>
          <h3>Student Information</h3>
          <p>Name: John Doe</p>
          <p>Email: john.doe@example.com</p>
          <p>Student ID: 123456</p>
          <p>Bio: Enthusiastic learner and coding enthusiast.</p>
        </>
      ),
    },
    {
      id: 'enrollment-details',
      title: 'Enrollment Details',
      icon: <FaBook />,
      content: (
        <>
          <h3>Enrollment Details</h3>
          <p>Currently enrolled courses:</p>
          <ul>
            <li>Full Stack Web Development</li>
            <li>Advanced JavaScript</li>
          </ul>
          <p>Batch Information: Batch 2024</p>
          <p>Mentor: Jane Smith</p>
        </>
      ),
    },
    {
      id: 'achievements-badges',
      title: 'Achievements and Badges',
      icon: <FaGraduationCap />,
      content: (
        <>
          <h3>Achievements and Badges</h3>
          <p>Achievements: Completed Full Stack Web Development with distinction.</p>
          <p>Badges: <span className="badge">JavaScript Expert</span></p>
          <p>Upcoming Goals: Complete React Native course.</p>
        </>
      ),
    },
    {
      id: 'current-courses',
      title: 'Current Courses',
      icon: <FaBook />,
      content: (
        <>
          <h3>Current Courses</h3>
          <p>Full Stack Web Development: 75% completed</p>
          <p>Advanced JavaScript: 60% completed</p>
        </>
      ),
    },
    {
      id: 'upcoming-courses',
      title: 'Upcoming Courses',
      icon: <FaCalendarAlt />,
      content: (
        <>
          <h3>Upcoming Courses</h3>
          <p>React Native Development</p>
          <p>Machine Learning with Python</p>
        </>
      ),
    },
    {
      id: 'completed-courses',
      title: 'Completed Courses',
      icon: <FaClipboardCheck />,
      content: (
        <>
          <h3>Completed Courses</h3>
          <p>Full Stack Web Development: A</p>
          <p>Advanced JavaScript: B+</p>
          <p>Certificates: <a href="#download">Download</a></p>
        </>
      ),
    },
    {
      id: 'course-overview',
      title: 'Course Overview',
      icon: <FaBook />,
      content: (
        <>
          <h3>Course Overview</h3>
          <p>Syllabus, Objectives, Instructor Info</p>
        </>
      ),
    },
    {
      id: 'pending-assignments',
      title: 'Pending Assignments',
      icon: <FaClipboardList />,
      content: (
        <>
          <h3>Pending Assignments</h3>
          <p>Assignment 1: Due on 20th July</p>
          <p>Assignment 2: Due on 25th July</p>
        </>
      ),
    },
    {
      id: 'submitted-assignments',
      title: 'Submitted Assignments',
      icon: <FaClipboardCheck />,
      content: (
        <>
          <h3>Submitted Assignments</h3>
          <p>Assignment 1: Feedback received, Grade: A</p>
          <p>Assignment 2: Awaiting feedback</p>
        </>
      ),
    },
    {
      id: 'project-workspace',
      title: 'Project Workspace',
      icon: <FaProjectDiagram />,
      content: (
        <>
          <h3>Project Workspace</h3>
          <p>Access to repositories, collaboration tools, project guidelines.</p>
        </>
      ),
    },
    {
      id: 'assignment-calendar',
      title: 'Assignment Calendar',
      icon: <FaCalendarAlt />,
      content: (
        <>
          <h3>Assignment Calendar</h3>
          <p>Visual calendar with assignment deadlines, reminders, sync options.</p>
        </>
      ),
    },
    {
      id: 'grades-overview',
      title: 'Grades Overview',
      icon: <FaChartLine />,
      content: (
        <>
          <h3>Grades Overview</h3>
          <p>Summary of grades for all courses, detailed breakdown, GPA calculation.</p>
        </>
      ),
    },
    {
      id: 'detailed-feedback',
      title: 'Detailed Feedback',
      icon: <FaComments />,
      content: (
        <>
          <h3>Detailed Feedback</h3>
          <p>Feedback on assignments and projects, instructor comments, improvement tips.</p>
        </>
      ),
    },
    {
      id: 'grade-trends',
      title: 'Grade Trends',
      icon: <FaChartLine />,
      content: (
        <>
          <h3>Grade Trends</h3>
          <p>Graphical representation of grade trends over time, comparison across courses.</p>
        </>
      ),
    },
    {
      id: 'class-schedule',
      title: 'Class Schedule',
      icon: <FaCalendarAlt />,
      content: (
        <>
          <h3>Class Schedule</h3>
          <p>Weekly timetable, class links, classroom locations.</p>
        </>
      ),
    },
    {
      id: 'upcoming-events',
      title: 'Upcoming Events',
      icon: <FaCalendarAlt />,
      content: (
        <>
          <h3>Upcoming Events</h3>
          <p>List of events, workshops, seminars, event details, registration links.</p>
        </>
      ),
    },
    {
      id: 'calendar-integration',
      title: 'Calendar Integration',
      icon: <FaCalendarAlt />,
      content: (
        <>
          <h3>Calendar Integration</h3>
          <p>Sync academic calendar with personal calendars, add custom events, set reminders.</p>
        </>
      ),
    },
    {
      id: 'reminders-notifications',
      title: 'Reminders and Notifications',
      icon: <FaBell />,
      content: (
        <>
          <h3>Reminders and Notifications</h3>
          <p>Automated reminders for classes, assignments, events, customizable notifications.</p>
        </>
      ),
    },
    {
      id: 'course-materials',
      title: 'Course Materials',
      icon: <FaBook />,
      content: (
        <>
          <h3>Course Materials</h3>
          <p>Access to lecture notes, reading materials, supplementary resources.</p>
        </>
      ),
    },
    {
      id: 'coding-resources',
      title: 'Coding Resources',
      icon: <FaBook />,
      content: (
        <>
          <h3>Coding Resources</h3>
          <p>Documentation, recommended libraries and tools, code examples.</p>
        </>
      ),
    },
    {
      id: 'reference-materials',
      title: 'Reference Materials',
      icon: <FaBook />,
      content: (
        <>
          <h3>Reference Materials</h3>
          <p>Books, articles, online resources, study guides.</p>
        </>
      ),
    },
    {
      id: 'video-lectures',
      title: 'Video Lectures',
      icon: <FaBook />,
      content: (
        <>
          <h3>Video Lectures</h3>
          <p>Access to recorded video lectures, tutorials, playback options.</p>
        </>
      ),
    },
    {
      id: 'announcements',
      title: 'Announcements',
      icon: <FaBell />,
      content: (
        <>
          <h3>Announcements</h3>
          <p>Latest announcements from instructors and administration, archive of past announcements.</p>
        </>
      ),
    },
    {
      id: 'messaging-system',
      title: 'Messaging System',
      icon: <FaComments />,
      content: (
        <>
          <h3>Messaging System</h3>
          <p>Internal messaging, direct messaging with instructors, peer communication.</p>
        </>
      ),
    },
    {
      id: 'discussion-forums',
      title: 'Discussion Forums',
      icon: <FaComments />,
      content: (
        <>
          <h3>Discussion Forums</h3>
          <p>Forums for class discussions, Q&A, topic-specific forums, moderation tools.</p>
        </>
      ),
    },
    {
      id: 'group-chats',
      title: 'Group Chats',
      icon: <FaComments />,
      content: (
        <>
          <h3>Group Chats</h3>
          <p>Group chats for team projects, study groups, class chats.</p>
        </>
      ),
    },
    {
      id: 'learning-analytics',
      title: 'Learning Analytics',
      icon: <FaChartLine />,
      content: (
        <>
          <h3>Learning Analytics</h3>
          <p>Visualizations of learning progress, time tracking, performance insights.</p>
        </>
      ),
    },
    {
      id: 'goal-setting',
      title: 'Goal Setting',
      icon: <FaClipboardCheck />,
      content: (
        <>
          <h3>Goal Setting</h3>
          <p>Set personal learning goals, track progress, goal reminders.</p>
        </>
      ),
    },
    {
      id: 'milestone-tracking',
      title: 'Milestone Tracking',
      icon: <FaClipboardCheck />,
      content: (
        <>
          <h3>Milestone Tracking</h3>
          <p>Track major course milestones, achievement dates, progress bar.</p>
        </>
      ),
    },
    {
      id: 'peer-comparison',
      title: 'Peer Comparison',
      icon: <FaChartLine />,
      content: (
        <>
          <h3>Peer Comparison</h3>
          <p>Anonymous comparison with peers, benchmarking, insights into areas for improvement.</p>
        </>
      ),
    },
  ];

  return (
    <div className="student-dashboard">
      <div className="sidebar">
        <h2>Harmosoft Student Dashboard</h2>
        <ul>
          {sections.map(section => (
            <li key={section.id}>
              <a href={`#${section.id}`} onClick={() => setSelectedSection(section.id)}>
                {section.icon} {section.title}
              </a>
              <ul className={`submenu ${selectedSection === section.id ? 'active' : ''}`}>
                <li><a href={`#${section.id}`}>{section.title}</a></li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className="main-content">
        {sections.map(section => (
          <div key={section.id} id={section.id} className={`section ${selectedSection === section.id ? 'active' : ''}`}>
            {section.content}
          </div>
        ))}
      </div>
      <div className="top-right">
        <div className="notifications">
          <FaBell />
          <span className="badge">3</span>
        </div>
        <div className="profile">
          <FaUser />
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
