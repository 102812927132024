import React, { useState } from 'react';
import './SupportDocumentation.css';

const supportDocs = [
  {
    title: 'PAWLSAR Manual',
    link: '/docs/pawlsar-manual.pdf',
    type: 'Manual',
  },
  {
    title: 'PAWLSAR FAQ',
    link: '/docs/pawlsar-faq.pdf',
    type: 'FAQ',
  },
  {
    title: 'HDS - Harmosoft Document Security Manual',
    link: '/docs/hds-manual.pdf',
    type: 'Manual',
  },
  {
    title: 'HDS - Harmosoft Document Security FAQ',
    link: '/docs/hds-faq.pdf',
    type: 'FAQ',
  },
  {
    title: 'AI-Powered Chatbots Manual',
    link: '/docs/ai-chatbots-manual.pdf',
    type: 'Manual',
  },
  {
    title: 'AI-Powered Chatbots FAQ',
    link: '/docs/ai-chatbots-faq.pdf',
    type: 'FAQ',
  },
];

function SupportDocumentation() {
  const [messageVisible, setMessageVisible] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setMessageVisible(true);
  };

  const closeMessage = () => {
    setMessageVisible(false);
  };

  return (
    <section className="support-documentation">
      <div className="container">
        <h2 className="docs-headline">Support Documentation</h2>
        <div className="docs-list">
          {supportDocs.map((doc, index) => (
            <div className="doc-item" key={index}>
              <h3 className="doc-title">{doc.title}</h3>
              <p className="doc-type">{doc.type}</p>
              <a href={doc.link} className="doc-link" onClick={handleClick}>
                View Document
              </a>
            </div>
          ))}
        </div>
        {messageVisible && (
          <div className="message-overlay" onClick={closeMessage}>
            <div className="message-box" onClick={(e) => e.stopPropagation()}>
              <h2 className="message-title">Notice</h2>
              <p className="message-content">
                This document is not yet updated and will be available soon. We are currently working on our website to improve your experience and provide up-to-date information. Please check back later for the latest documents.
              </p>
              <button className="message-close-button" onClick={closeMessage}>Close</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default SupportDocumentation;
