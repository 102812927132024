// src/components/academy/Webinars.js
import React, { useState } from 'react';
import './Webinars.css';

const webinarData = [
  {
    id: 1,
    title: 'Building Scalable Web Applications',
    date: '2024-08-01',
    description: 'Learn how to build scalable web applications using modern technologies.',
    details: 'In this webinar, you will learn about various strategies and tools for building scalable web applications. We will cover topics such as load balancing, caching, database optimization, and more. The session will also include real-world examples and best practices for scaling your web applications.',
    link: 'https://www.example.com/register',
  },
  {
    id: 2,
    title: 'Introduction to Machine Learning',
    date: '2024-08-15',
    description: 'A beginner\'s guide to machine learning and its applications.',
    details: 'This webinar will introduce you to the basics of machine learning, including supervised and unsupervised learning, key algorithms, and how to implement them using popular frameworks like TensorFlow and Scikit-learn. You will also learn about real-world applications of machine learning and get hands-on experience with a simple project.',
    link: 'https://www.example.com/register',
  },
  {
    id: 3,
    title: 'Effective DevOps Practices',
    date: '2024-08-29',
    description: 'Learn about the best practices for implementing DevOps in your organization.',
    details: 'In this webinar, we will explore the core principles of DevOps and how to implement them effectively in your organization. Topics include continuous integration and continuous deployment (CI/CD), infrastructure as code, monitoring and logging, and collaboration tools. You will also learn about common challenges and how to overcome them.',
    link: 'https://www.example.com/register',
  },
  // Add more webinars similarly
  // ...
];

function Webinars({ isAdmin }) {
  const [expandedWebinars, setExpandedWebinars] = useState([]);

  const toggleWebinarDetails = (webinarId) => {
    setExpandedWebinars((prevExpandedWebinars) =>
      prevExpandedWebinars.includes(webinarId)
        ? prevExpandedWebinars.filter((id) => id !== webinarId)
        : [...prevExpandedWebinars, webinarId]
    );
  };

  return (
    <section className="webinars">
      <div className="container">
        <h2 className="headline">Webinars</h2>
        <div className="webinar-list">
          {webinarData.map((webinar) => (
            <div className="webinar-item" key={webinar.id}>
              <h3 className="webinar-title">{webinar.title}</h3>
              <p className="webinar-date">{webinar.date}</p>
              <p className="webinar-description">
                {expandedWebinars.includes(webinar.id)
                  ? webinar.details
                  : `${webinar.details.substring(0, 100)}...`}
              </p>
              <button
                className="toggle-details-button"
                onClick={() => toggleWebinarDetails(webinar.id)}
              >
                {expandedWebinars.includes(webinar.id) ? 'Read less' : 'Read more'}
              </button>
              <a
                href={webinar.link}
                className="register-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Register for this webinar
              </a>
              {isAdmin && (
                <div className="webinar-actions">
                  <button className="edit-button">Edit</button>
                  <button className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Webinars;
