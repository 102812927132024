// src/components/academy/Tutorials.js
import React, { useState } from 'react';
import './Tutorials.css';

const tutorialData = [
  {
    id: 1,
    title: 'Introduction to Full Stack Development',
    date: '2024-07-01',
    description: 'Learn the basics of full stack development with this comprehensive tutorial.',
    details: 'This tutorial covers both front-end and back-end development using popular technologies like HTML, CSS, JavaScript, React, Node.js, and MongoDB. You will learn how to set up your development environment, create RESTful APIs with Node.js, build responsive user interfaces with React, and manage data with MongoDB. The tutorial includes hands-on projects to help you apply what you\'ve learned and build real-world applications.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 2,
    title: 'Advanced JavaScript Techniques',
    date: '2024-06-25',
    description: 'Deep dive into advanced JavaScript techniques and features.',
    details: 'Explore advanced JavaScript topics including ES6, async/await, closures, and more to enhance your coding skills. This tutorial will teach you how to write more efficient and maintainable code by leveraging the latest JavaScript features. You\'ll learn about asynchronous programming with promises and async/await, working with modules, and advanced concepts like closures and higher-order functions.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 3,
    title: 'Microservices Architecture with Docker and Kubernetes',
    date: '2024-06-20',
    description: 'Learn how to build, deploy, and manage microservices using Docker and Kubernetes.',
    details: 'Understand the principles of microservices architecture and how to implement them using Docker and Kubernetes. This tutorial covers containerization with Docker, orchestration with Kubernetes, and best practices for building scalable and resilient microservices.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 4,
    title: 'Data Science and Machine Learning with Python',
    date: '2024-06-15',
    description: 'Master data science and machine learning techniques using Python and popular libraries.',
    details: 'This tutorial includes comprehensive training on data manipulation, analysis, and visualization using Python. You will learn how to use libraries like NumPy, pandas, Matplotlib, and Scikit-learn to perform data analysis and build machine learning models.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 5,
    title: 'Mobile App Development with React Native',
    date: '2024-06-10',
    description: 'Learn how to build cross-platform mobile applications using React Native.',
    details: 'Develop mobile applications that work on both Android and iOS using the React Native framework. This tutorial covers the basics of React Native, navigation, state management, and integrating with APIs to build fully functional mobile apps.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 6,
    title: 'DevOps Engineering with AWS and Terraform',
    date: '2024-06-05',
    description: 'Get hands-on experience with DevOps practices and tools using AWS and Terraform.',
    details: 'Learn how to automate infrastructure and deployment processes using AWS services and Terraform. This tutorial covers infrastructure as code, continuous integration, continuous deployment, and monitoring in a DevOps environment.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 7,
    title: 'Cybersecurity Fundamentals',
    date: '2024-06-01',
    description: 'Learn the fundamentals of cybersecurity, including risk management, encryption, and network security.',
    details: 'Understand the basics of cybersecurity and how to protect systems from cyber threats. This tutorial covers topics such as risk assessment, cryptography, network security, and incident response.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 8,
    title: 'AI and Deep Learning with TensorFlow',
    date: '2024-05-25',
    description: 'Master the basics of AI and deep learning using TensorFlow and Keras.',
    details: 'Get hands-on experience with AI and deep learning techniques using TensorFlow and Keras libraries. This tutorial covers neural networks, deep learning models, and how to train and deploy AI models.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 9,
    title: 'Blockchain Development with Ethereum',
    date: '2024-05-20',
    description: 'Learn how to develop decentralized applications (DApps) on the Ethereum blockchain.',
    details: 'Understand the principles of blockchain technology and how to build DApps on the Ethereum platform. This tutorial covers smart contracts, Solidity programming, and deploying DApps to the Ethereum network.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 10,
    title: 'Cloud Computing with Microsoft Azure',
    date: '2024-05-15',
    description: 'Gain expertise in cloud computing services and solutions using Microsoft Azure.',
    details: 'Learn how to deploy and manage cloud applications using Microsoft Azure services. This tutorial covers virtual machines, Azure App Services, storage, databases, and networking in the cloud.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 11,
    title: 'Backend Development with Django and Python',
    date: '2024-05-10',
    description: 'Learn how to build robust backend systems using Django and Python.',
    details: 'Develop scalable backend systems using the Django framework and Python programming language. This tutorial covers Django models, views, templates, and RESTful APIs.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 12,
    title: 'Frontend Development with Vue.js',
    date: '2024-05-05',
    description: 'Master the fundamentals of frontend development using Vue.js.',
    details: 'Understand the core concepts of Vue.js and how to build interactive web interfaces. This tutorial covers Vue components, directives, state management, and Vue Router.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 13,
    title: 'Continuous Integration and Continuous Deployment (CI/CD)',
    date: '2024-05-01',
    description: 'Learn how to implement CI/CD pipelines using Jenkins and GitHub Actions.',
    details: 'Automate the build, test, and deployment processes using CI/CD tools like Jenkins and GitHub Actions. This tutorial covers setting up CI/CD pipelines, running automated tests, and deploying applications.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 14,
    title: 'Big Data Analytics with Hadoop and Spark',
    date: '2024-04-25',
    description: 'Get hands-on experience with big data processing and analytics using Hadoop and Spark.',
    details: 'Learn how to process and analyze large datasets using Hadoop and Spark frameworks. This tutorial covers HDFS, MapReduce, Spark Core, Spark SQL, and Spark Streaming.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 15,
    title: 'UI/UX Design Principles',
    date: '2024-04-20',
    description: 'Learn the principles of UI/UX design and how to create user-friendly interfaces.',
    details: 'Understand the key principles of user interface and user experience design to create intuitive applications. This tutorial covers user research, wireframing, prototyping, and usability testing.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 16,
    title: 'Agile Project Management with Scrum',
    date: '2024-04-15',
    description: 'Understand the principles of Agile project management and how to apply Scrum methodologies.',
    details: 'Learn how to manage projects using Agile methodologies and Scrum framework. This tutorial covers Scrum roles, ceremonies, artifacts, and how to implement Agile practices in your projects.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 17,
    title: 'Introduction to Artificial Intelligence',
    date: '2024-04-10',
    description: 'Explore the basics of artificial intelligence and its applications across various industries.',
    details: 'Get an overview of AI concepts and how they are applied in different industries. This tutorial covers machine learning, natural language processing, computer vision, and AI ethics.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 18,
    title: 'Internet of Things (IoT) Development',
    date: '2024-04-05',
    description: 'Learn how to develop IoT applications and integrate them with cloud services.',
    details: 'Understand the principles of IoT and how to develop applications that connect with cloud services. This tutorial covers IoT protocols, hardware, software, and cloud integration.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 19,
    title: 'Network Engineering and Management',
    date: '2024-04-01',
    description: 'Gain knowledge in network engineering, including design, management, and troubleshooting.',
    details: 'Learn about network design, management, and troubleshooting techniques. This tutorial covers networking fundamentals, protocols, routing, switching, and network security.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
  {
    id: 20,
    title: 'Programming with Python for Beginners',
    date: '2024-03-25',
    description: 'Learn the basics of programming with Python, one of the most popular programming languages.',
    details: 'Get started with Python programming and understand its applications. This tutorial covers Python syntax, data structures, control flow, functions, and modules.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: true,
  },
  {
    id: 21,
    title: 'Modern Web Design with HTML, CSS, and JavaScript',
    date: '2024-03-20',
    description: 'Master modern web design principles using HTML, CSS, and JavaScript.',
    details: 'Learn how to design and build modern websites using HTML, CSS, and JavaScript. This tutorial covers responsive design, CSS frameworks, JavaScript frameworks, and web performance optimization.',
    link: 'https://www.youtube.com/watch?v=XXXXXXXX',
    isPaid: false,
  },
];

function Tutorials({ isAdmin }) {
  const [expandedTutorials, setExpandedTutorials] = useState([]);

  const toggleTutorialDetails = (tutorialId) => {
    setExpandedTutorials((prevExpandedTutorials) =>
      prevExpandedTutorials.includes(tutorialId)
        ? prevExpandedTutorials.filter((id) => id !== tutorialId)
        : [...prevExpandedTutorials, tutorialId]
    );
  };

  return (
    <section className="tutorials">
      <div className="container">
        <h2 className="headline">Tutorials</h2>
        <div className="tutorial-list">
          {tutorialData.map((tutorial) => (
            <div className="tutorial-item" key={tutorial.id}>
              <h3 className="tutorial-title">{tutorial.title}</h3>
              <p className="tutorial-date">{tutorial.date}</p>
              <p className="tutorial-description">
                {expandedTutorials.includes(tutorial.id)
                  ? tutorial.details
                  : `${tutorial.details.substring(0, 100)}...`}
              </p>
              <button
                className="toggle-details-button"
                onClick={() => toggleTutorialDetails(tutorial.id)}
              >
                {expandedTutorials.includes(tutorial.id) ? 'Read less' : 'Read more'}
              </button>
              {tutorial.isPaid ? (
                <button className="watch-button">Watch this tutorial</button>
              ) : (
                <a
                  href={tutorial.link}
                  className="watch-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch this tutorial
                </a>
              )}
              {isAdmin && (
                <div className="tutorial-actions">
                  <button className="edit-button">Edit</button>
                  <button className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Tutorials;
