// src/components/academy/CareerDevelopment.js
import React, { useState } from 'react';
import './CareerDevelopment.css';

const careerContent = {
  overview: {
    title: 'Career Services Overview',
    content: 'We offer comprehensive career services to help you achieve your professional goals. Our career development programs are designed to equip you with the skills and knowledge needed to succeed in the tech industry.'
  },
  counseling: {
    title: 'Career Counseling and Coaching',
    content: 'Our one-on-one career counseling sessions provide personalized guidance. Our career coaches offer mentoring programs to help you navigate your career path. Meet our expert coaches and explore their areas of expertise.'
  },
  resume: {
    title: 'Resume and Portfolio Assistance',
    content: 'Learn how to build a professional resume and portfolio. Access examples and templates, and get tips on how to effectively showcase your projects and skills.'
  },
  interview: {
    title: 'Interview Preparation',
    content: 'Prepare for technical and behavioral interviews with our resources. Practice common interview questions and participate in mock interview sessions with feedback.'
  },
  jobSearch: {
    title: 'Job Search Strategies',
    content: 'Discover effective job search strategies. Find job boards and websites relevant to the tech industry, and learn about networking strategies and events.'
  },
  workshops: {
    title: 'Workshops and Webinars',
    content: 'Stay updated with our schedule of upcoming career-related workshops and webinars. Access recordings of past events and workshops.'
  },
  alumni: {
    title: 'Alumni Success Stories',
    content: 'Read testimonials and case studies from our successful alumni. Explore their career paths and achievements.'
  },
  networking: {
    title: 'Industry Connections and Networking',
    content: 'Learn about our partnerships with tech companies and startups. Participate in networking events, job fairs, and meetups. Connect with our alumni network for support and job referrals.'
  },
  jobPlacement: {
    title: 'Job Placement Support',
    content: 'Get details on our job placement assistance and success rates. Explore companies that have hired our graduates and find internship opportunities and industry projects.'
  },
  resources: {
    title: 'Additional Resources',
    content: 'Access articles, guides, and e-books on career development. Find links to online courses and certifications, and utilize career development tools and software.'
  }
};

function CareerDevelopment({ isAdmin }) {
  const [editableSection, setEditableSection] = useState(null);
  const [content, setContent] = useState(careerContent);

  const handleEdit = (section) => {
    setEditableSection(section);
  };

  const handleSave = (section) => {
    setEditableSection(null);
  };

  const handleChange = (e, section) => {
    setContent({
      ...content,
      [section]: {
        ...content[section],
        content: e.target.value
      }
    });
  };

  return (
    <section className="career-development">
      <div className="container">
        <h2 className="headline">Career Development and Mentorship</h2>
        <div className="content-sections">
          {Object.keys(content).map((section) => (
            <div className="content-section" key={section}>
              <h3 className="section-title">{content[section].title}</h3>
              {editableSection === section ? (
                <textarea
                  className="section-content-edit"
                  value={content[section].content}
                  onChange={(e) => handleChange(e, section)}
                />
              ) : (
                <p className="section-content">{content[section].content}</p>
              )}
              {isAdmin && (
                <button
                  className="edit-button"
                  onClick={() =>
                    editableSection === section
                      ? handleSave(section)
                      : handleEdit(section)
                  }
                >
                  {editableSection === section ? 'Save' : 'Edit'}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CareerDevelopment;
