// src/components/academy/Enrollments.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Enrollments.css';

function Enrollments() {
  return (
    <section className="enrollments">
      <div className="enrollments-container">
        <h2>Enrollments</h2>
        <p>You need to log in to our Student Dashboard to enroll in a course.</p>
        <Link to="/client-portal/login-signup" className="login-link">
          Log in to Student Dashboard
        </Link>
      </div>
    </section>
  );
}

export default Enrollments;
