// src/components/academy/CourseListings.js
import React, { useState } from 'react';
import './CourseListings.css';

const initialCourses = [
  { id: 1, title: 'Full Stack Web Development with React and Node.js', date: '2024-07-01', description: 'Learn how to build full stack web applications using React, Node.js, Express, and MongoDB.' },
  { id: 2, title: 'Advanced JavaScript and ES6', date: '2024-06-25', description: 'Deep dive into modern JavaScript features including ES6, async/await, and more.' },
  { id: 3, title: 'Microservices Architecture with Docker and Kubernetes', date: '2024-06-20', description: 'Learn how to build, deploy, and manage microservices using Docker and Kubernetes.' },
  { id: 4, title: 'Data Science and Machine Learning with Python', date: '2024-06-15', description: 'Master data science and machine learning techniques using Python and popular libraries.' },
  { id: 5, title: 'Mobile App Development with React Native', date: '2024-06-10', description: 'Learn how to build cross-platform mobile applications using React Native.' },
  { id: 6, title: 'DevOps Engineering with AWS and Terraform', date: '2024-06-05', description: 'Get hands-on experience with DevOps practices and tools using AWS and Terraform.' },
  { id: 7, title: 'Cybersecurity Fundamentals', date: '2024-06-01', description: 'Learn the fundamentals of cybersecurity, including risk management, encryption, and network security.' },
  { id: 8, title: 'AI and Deep Learning with TensorFlow', date: '2024-05-25', description: 'Master the basics of AI and deep learning using TensorFlow and Keras.' },
  { id: 9, title: 'Blockchain Development with Ethereum', date: '2024-05-20', description: 'Learn how to develop decentralized applications (DApps) on the Ethereum blockchain.' },
  { id: 10, title: 'Cloud Computing with Microsoft Azure', date: '2024-05-15', description: 'Gain expertise in cloud computing services and solutions using Microsoft Azure.' },
  { id: 11, title: 'Backend Development with Django and Python', date: '2024-05-10', description: 'Learn how to build robust backend systems using Django and Python.' },
  { id: 12, title: 'Frontend Development with Vue.js', date: '2024-05-05', description: 'Master the fundamentals of frontend development using Vue.js.' },
  { id: 13, title: 'Continuous Integration and Continuous Deployment (CI/CD)', date: '2024-05-01', description: 'Learn how to implement CI/CD pipelines using Jenkins and GitHub Actions.' },
  { id: 14, title: 'Big Data Analytics with Hadoop and Spark', date: '2024-04-25', description: 'Get hands-on experience with big data processing and analytics using Hadoop and Spark.' },
  { id: 15, title: 'UI/UX Design Principles', date: '2024-04-20', description: 'Learn the principles of UI/UX design and how to create user-friendly interfaces.' },
  { id: 16, title: 'Agile Project Management with Scrum', date: '2024-04-15', description: 'Understand the principles of Agile project management and how to apply Scrum methodologies.' },
  { id: 17, title: 'Introduction to Artificial Intelligence', date: '2024-04-10', description: 'Explore the basics of artificial intelligence and its applications across various industries.' },
  { id: 18, title: 'Internet of Things (IoT) Development', date: '2024-04-05', description: 'Learn how to develop IoT applications and integrate them with cloud services.' },
  { id: 19, title: 'Network Engineering and Management', date: '2024-04-01', description: 'Gain knowledge in network engineering, including design, management, and troubleshooting.' },
  { id: 20, title: 'Programming with Python for Beginners', date: '2024-03-25', description: 'Learn the basics of programming with Python, one of the most popular programming languages.' },
  { id: 21, title: 'Modern Web Design with HTML, CSS, and JavaScript', date: '2024-03-20', description: 'Master modern web design principles using HTML, CSS, and JavaScript.' },
  
];

function CourseListings({ isAdmin }) {
  const [courses, setCourses] = useState(initialCourses);
  const [formData, setFormData] = useState({ title: '', date: '', description: '' });
  const [editingCourse, setEditingCourse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingCourse) {
      setCourses(courses.map(course => course.id === editingCourse.id ? { ...editingCourse, ...formData } : course));
      setEditingCourse(null);
    } else {
      setCourses([...courses, { ...formData, id: Date.now() }]);
    }
    setFormData({ title: '', date: '', description: '' });
  };

  const handleEdit = (course) => {
    setEditingCourse(course);
    setFormData(course);
  };

  const handleDelete = (id) => {
    setCourses(courses.filter(course => course.id !== id));
  };

  return (
    <section className="course-listings">
      <div className="container">
        <h2 className="headline">Course Listings</h2>
        <div className="course-list">
          {courses.map((course) => (
            <div className="course-item" key={course.id}>
              <h3 className="course-title">{course.title}</h3>
              <p className="course-date">{course.date}</p>
              <p className="course-description">{course.description}</p>
              <button className="register-button">Register for this course</button>
              {isAdmin && (
                <div className="course-actions">
                  <button onClick={() => handleEdit(course)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(course.id)} className="delete-button">Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
        {isAdmin && (
          <div className="course-form">
            <h3 className="form-headline">{editingCourse ? 'Edit Course' : 'Add New Course'}</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea id="description" name="description" value={formData.description} onChange={handleChange} required></textarea>
              </div>
              <button type="submit" className="submit-button">{editingCourse ? 'Update Course' : 'Add Course'}</button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}

export default CourseListings;
