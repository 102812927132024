// src/components/products/ProductList.js
import React from 'react';
import './ProductList.css';

const products = [
  {
    name: 'PAWLSAR',
    description: 'One of the most distressing aspects of pet ownership is the fear of losing a beloved dog. PAWLSAR is an integrated solution that addresses the primary concern of locating lost pets while also consolidating all aspects of pet management.',
    features: [
      'Dog Tracker: tracking of the lost dog utilizing BLE tracking tags.',
      'Social Media: Create, comment on, like posts, and follow users.',
      'Marketplace: Transactions for pet-related services like selling and buying of dogs,grooming, training, veterinary care.',
      'QR Code: Generation and scanning for pet identification and recovery.',
      
    ],
  },
  {
    name: 'HDS - Harmosoft Document Security',
    description: 'AI-powered Document Scam Detector and Authenticator App designed to combat document fraud and safeguard businesses from reputational damage.',
    features: [
      'Electronic Document Stamping',
      'Unique Serial number and QR Code Generation',
      'Verifications of documents legitimacy',
      'Document Organization and Storage',
      'Document Traceability',
      'Contract Document Management',
      'Confidential Document Conversion',
    ],
  },
  
  {
    name: 'AI-Powered Chatbots',
    description: 'Enhance customer engagement with AI-powered chatbots.',
    features: [
      'Natural Language Processing',
      '24/7 Customer Support',
      'Integration with Existing Systems',
      'Customizable Responses',
      'Analytics and Reporting',
    ],
  },
 
];

function ProductList() {
  return (
    <section className="product-list">
      <div className="container">
        <h2 className="products-headline">Some Of Our Products</h2>
        <div className="products">
          {products.map((product, index) => (
            <div className="product-item" key={index}>
              <h3 className="product-name">{product.name}</h3>
              <p className="product-description">{product.description}</p>
              <ul className="product-features">
                {product.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="product-feature">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProductList;
